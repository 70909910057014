import { mapState } from 'vuex'
import { createNotification } from '@/lib/unnotificationsqueue'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { IS_CAC } from '@/variables'

export default {
  name: 'CampaignTemplateTable',
  components: { CrudTable },
  data () {
    return {
      storeModule: 'campaignTemplates'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),

    title () {
      return this.$t('campaigns.campaignTemplate')
    },

    /**
   * Computes if "create campaign from template" shortcut is to be shown
   * @returns {boolean}
   */
    showCreateCampaignFromTemplate () {
      return IS_CAC
    },

    /**
   * Computes name and path of each row's link in the CrudTable
   * @returns {object} name (label) to show and path to navigate to
   */
    linkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  },

  methods: {
    /**
   * Creates new CampaignTemplate and navigates to its detail
   * @returns {Promise<void>}
   */
    async onCreateCampaignTemplate () {
      let data = await this.$store.dispatch('campaignTemplates/createItem')
      await this.$router.push({
        name: 'CampaignTemplateDetail',
        params: { id: data.id }
      })
    },

    /**
   * Shortcut to "create campaign from template" action
   * @param {item} CampaignTemplate selected to create new Campaign from
   */
    async createCampaignFromTemplate (item) {
      item.action_loading = true
      try {
        let response = await this.$store.dispatch('campaignTemplates/createCampaignFromTemplateId', item.id)
        await this.$store.dispatch('addNotification', createNotification(response.message, response.level))
        const redirect = response.redirect_route_name
        const campaignId = response.redirect_route_url_params && response.redirect_route_url_params['id']
        this.$router.push({ name: redirect, params: { id: campaignId } })
      } catch (error) {
        await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
      } finally {
        item.action_loading = false
      }
    }
  }
}
