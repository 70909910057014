<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <CampaignTemplateTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import CampaignTemplateTable from '@/apps/dido/components/tables/CampaignTemplateTable/CampaignTemplateTable.vue'
import sections from './defaultNavigationDrawerSections'

export default {
  name: 'CampaignTemplateList',
  components: { CampaignTemplateTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('campaigns.campaignTemplateList')
    }
  }
}
</script>
